import Landing from "../pages/Landing";
import Events from "../pages/Events";
import PastEvents from "../pages/PastEvents";
import Login from "../pages/auth/Login";
import Event from "../pages/Event";
import Root from "./Root";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../pages/auth/Login";
import { Navigate } from "react-router-dom";

const routes = [
  { path: "/", element: <Root /> },

  {
    // public routes
    path: '/',
    element: <PublicRoute />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      }
    ],
  },
  {
    // Private routes
    path: '/events',
    element: <PrivateRoute />,
    children: [
      {
        path: '',
        outside: false,
        exact: true,
        element: <Navigate to="/events/list" replace />,
      },
      {
        path: 'list',
        outside: false,
        exact: true,
        element: <Events />,
      },
      {
        path: 'past-events',
        outside: false,
        exact: true,
        element: <PastEvents />,
      },
      {
        path: 'event/:id',
        outside: false,
        exact: true,
        element: <Event />,
      },
    ],
  },
{
  path: "*",
    element: <Navigate to="/events/list" replace />
},
];

export default routes;
