import { createContext } from "react";

const Partner = createContext({
  partner : {},
  setPartner : () => {}
});

const Organization = createContext({
  organization : {},
  setOrganization : () => {}
});

const Authenticated = createContext({
  authenticated : false,
  setAuthenticated : () => Boolean
});

export { Partner, Organization, Authenticated };