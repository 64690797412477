import getCompany from '../api/company';
import { Partner, Organization, Authenticated } from '../context/SessionContext';
import { CompanyContext } from '../context/Company';
import { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Get } from '../api/Server';
import Main from '../layouts/Main';
import { toast } from 'react-toastify';

const PrivateRoute = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { setCompany } = useContext(CompanyContext)
  const { authenticated, setAuthenticated } = useContext(Authenticated);
  const { setPartner } = useContext(Partner);
  const { setOrganization } = useContext(Organization);

  useEffect(() => {
    async function getData() {
      let myCompany = await getCompany();
      let getAuth = await Get('/partnerlogins');
      setCompany(myCompany);
      setPartner(getAuth?.partner);
      setAuthenticated(getAuth.authenticated)
      setOrganization(getAuth?.organization);
    }
    getData()
  }, [setAuthenticated, setCompany, setPartner, setOrganization]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const getAuth = await Get('/partnerlogins');
        if (getAuth.authenticated) {
          setAuthenticated(getAuth.authenticated);
        } else {
          setAuthenticated(false);
          navigate('/login');
        }
      } catch (error) {
        toast.error(`Authentication check failed`)
        setAuthenticated(false);
        navigate('/login');
      }
    };

    const interval = setInterval(checkAuthentication, 60000);

    return () => clearInterval(interval);
  }, [authenticated, setAuthenticated]);

  return (
    <>
      {!authenticated ? <Navigate to={'/login'} /> : null}
      <Main>
        <Outlet />
      </Main>
    </>
  );
};

export default PrivateRoute;