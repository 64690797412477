export default function mapTransactionsForAffiliates(data) {
  let affiliateData = {};

  data.affiliates.forEach(affiliate => {
    affiliateData[affiliate._id] = {
      affiliateDetails: affiliate,
      transactions: [],
      totalAmount: 0,
      views: 0
    };
  });

  data.tracking.forEach(track => {
    if (affiliateData[track.affiliateCompany]) {
      affiliateData[track.affiliateCompany].views++;
    }
  });

  data.transactions.forEach(transaction => {
    if (affiliateData[transaction.affId]) {
      affiliateData[transaction.affId].transactions.push(transaction);
      affiliateData[transaction.affId].totalAmount += transaction.total;
    }
  });

  // Convert to array, filter for views > 0, and sort by affiliate name
  return Object.values(affiliateData)
    .filter(affiliate => affiliate.views > 0)
    .sort((a, b) => {
      const nameA = a.affiliateDetails.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.affiliateDetails.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
}