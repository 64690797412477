import React, { useContext, useState } from "react";
import logo from "../../images/lob.png";
import { useNavigate } from "react-router-dom";
import { CompanyContext } from "../../context/Company";
import { Button } from "../button";
import { Post } from "../../api";
import { Authenticated, Organization, Partner } from "../../context/SessionContext";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();
  const { company } = useContext(CompanyContext);
  const { setAuthenticated } = useContext(Authenticated);
  const { setPartner } = useContext(Partner);
  const { setOrganization } = useContext(Organization);
  const [formData, setFormData] = useState({
    username: '',
    passcode: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.username == '' || formData.passcode == '') {
      toast.error(`Please fill in all fields`)
      return;
    }
    Post('/partnerlogins', {
      username: formData.username,
      passcode: formData.passcode,
      company: company?._id
    }).then(res => {
      console.table(res?.organization);
      setAuthenticated(true);
      setPartner(res?.partner);
      setOrganization(res?.organization);
      toast.success(`Welcome Back. You're logged in under ${res.organization?.name}'s account`)
      navigate('/events/list');
    }).catch(err => {
      toast.error(`Invalid username or passcode`)
      setFormData(prev => ({
        ...prev,
        passcode: ''
      }))
    });
  };

  return (
    <>
      <div
        className="flex flex-col px-[80px] md:px-[100px] sm:px-[100px] justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 h-screen"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-[-200px]">
          <img
            alt="Logo"
            src={company?.logoLight || logo}
            className="mx-auto h-20 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight">
            Partners Portal
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6">
            <div>
              <label htmlFor="username" className="block text-sm/6 font-medium">
                Username
              </label>
              <div className="mt-2">
                <input
                  id="username"
                  name="username"
                  type="text"
                  required
                  value={formData.username}
                  onChange={handleChange}
                  autoComplete="username"
                  className="block w-full rounded-md bg-[#1e1e1e] outline-sky-600 px-3 py-1.5 text-base outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-700 sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="passcode"
                  className="block text-sm/6 font-medium"
                >
                  Passcode
                </label>

              </div>
              <div className="mt-2">
                <input
                  id="passcode"
                  name="passcode"
                  type="password"
                  required
                  value={formData.passcode}
                  onChange={handleChange}
                  autoComplete="current-password"
                  className="block w-full rounded-md bg-[#1e1e1e] px-3 py-1.5 text-base outline outline-1 outline-sky-600 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-700 sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <Button
                onClick={handleSubmit}
                className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign in
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
