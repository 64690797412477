import "./App.css";
import "./index.css";
import "./assets/styles/main.scss";
import "./assets/styles/rj.scss";
import getCompany from './services/company';
import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import { CompanyContext } from "./context/Company";
import { Authenticated, Organization, Partner } from "./context/SessionContext";
import { ToastContainer } from "react-toastify";
function App() {
  const router = createBrowserRouter(routes);
  const [company, setCompany] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const [partner, setPartner] = useState({});
  const [organization, setOrganization] = useState({});

  const partnerCompany = { company, setCompany };
  const partnerSession = { authenticated, setAuthenticated };
  const partnerOrganization = { organization, setOrganization };
  const partnerPartner = { partner, setPartner };

  useEffect(() => {
    async function getData() {
      let myCompany = await getCompany();
      setCompany(myCompany);
      document.title = `${myCompany?.name}'s Partner Dashboard` || 'Partner Dashboard';
    }
    getData()
  }, [])

  return (
    <>
      <Authenticated.Provider value={partnerSession}>
        <CompanyContext.Provider value={partnerCompany}>
          <Organization.Provider value={partnerOrganization}>
            <Partner.Provider value={partnerPartner}>
              <RouterProvider router={router} />
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
              />
            </Partner.Provider>
          </Organization.Provider>
        </CompanyContext.Provider>
      </Authenticated.Provider>
    </>
  );
}

export default App;
