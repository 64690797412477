function usdConverter(number) {
  // Check if the number is null, undefined, or not a number
  if (number === null || number === undefined || isNaN(number)) {
      return '$0.00';
  }

  if (number >= 1000000) {
      return `$${(number / 1000000).toFixed(1)}M`; // Show millions with one decimal
  }

  if (number >= 100000) {
      return `$${(number / 1000).toFixed(0)}K`; // Show thousands with no decimals
  }

  if (number >= 10000) {
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0, // No cents for values over $10,000
          maximumFractionDigits: 0
      }).format(number);
  }

  return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2, // Show cents for values below $10,000
      maximumFractionDigits: 2
  }).format(number);
}

export default usdConverter;