import React from 'react'
import { Row } from 'react-bootstrap'
import { BiLogOut } from 'react-icons/bi'
import { Outlet, useNavigate } from 'react-router-dom'
import { Delete } from '../api'
import { useContext } from 'react'
import { Authenticated, Partner, Organization } from '../context/SessionContext'
import { toast } from 'react-toastify'
import { CompanyContext } from '../context/Company'

export default function Main() {
  const {company} = useContext(CompanyContext);
  const { setAuthenticated } = useContext(Authenticated);
  const { organization, setOrganization } = useContext(Organization);
  const { setPartner } = useContext(Partner);

  const navigate = useNavigate();

  const logout = () => {
    Delete('/partnerlogins')
      .then(res => {
        setPartner({});
        setOrganization({});
        setAuthenticated(false);
        toast.success(`Logged Out`)
        navigate('/login');
      }).catch(err => {
        console.table(err);
      })
  }

  return (
    <>
      <Row className="p-5 px-[35px] md:px-[100px] sm:px-[100px] max-w-[1500px] mx-auto">
        <div className="w-full flex gap-4 justify-between items-center">
          <div className="flex items-center justify-center gap-2">
            <img src={organization?.logo} alt="logo" className="w-10 h-10 rounded-full" />
            <span className="text-2xl font-bold text-gray-300">{organization?.name}</span>
          </div>
          <BiLogOut onClick={logout} color="text-gray-300" className="cursor-pointer" size={18} />
        </div>
      </Row>
      <div className="p-5 py-10 px-[35px] md:px-[100px] sm:px-[100px] max-w-[1500px] mx-auto">
        <Outlet />
      </div>
    </>
  )
}
