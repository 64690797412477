import jQuery from 'jquery';
const APIRoute = '/api';
// let APIRoute = 'https://ios.liveonbeat.ngrok.io/api';
// let APIRoute = 'http://localhost:4242/api';
const companyId = '61e2056c65fba2004e536f70';

function Get(url) {
  return jQuery.getJSON(`${APIRoute}${url}`);
}

function GetOutside(url) {
  return jQuery.getJSON(`${url}`);
}

function Post(url, data) {
  if (typeof data === typeof {}) {
    data = JSON.stringify(data);
  }
  return jQuery.ajax({
    url: `${APIRoute}${url}`,
    type: 'POST',
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    data: data
  });
}

function PostWithMedia(route, params) {
  const baseUrl = route;
  const formData = new FormData();

  for (const [key, value] of Object.entries(params)) {
    if (key === "files" && typeof value !== 'undefined') {
      for (let i = 0; i < value.length; i++) {
        formData.append("files", value[i]);
      }
    }
    formData.append(key, value);
  }

  return jQuery.ajax({
    url: `${APIRoute}${baseUrl}`,
    type: 'POST',
    data: formData,
    cache: false,
    dataType: 'json',
    processData: false,
    contentType: false,
  });
}

function PutWithMedia(route, params) {
  const baseUrl = route;

  const formData = new FormData();
  for (const k in params) {
    if (k === "files" && typeof params[k] !== 'undefined') {
      for (let foo = 0; foo < params[k].length; foo++) {
        formData.append("files", params[k][foo]);
      }
    }
    formData.append(k, params[k]);
  }
  return jQuery.ajax({
    url: `${APIRoute}${route}`,
    type: 'PUT',
    data: formData,
    cache: false,
    dataType: 'json',
    processData: false, // Don't process the files
    contentType: false, // Set content type to false as jQuery will tell the server its a query string request
  })
}

function Put(url, data) {
  if (typeof data === typeof {}) {
    data = JSON.stringify(data);
  }
  return jQuery.ajax({
    url: `${APIRoute}${url}`,
    type: 'PUT',
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    data: data
  });
}

function Delete(url) {
  return jQuery.ajax({
    url: `${APIRoute}${url}`,
    type: 'DELETE',
    contentType: "application/json; charset=utf-8",
    dataType: "json",
  });
}

export { Get, Post, PostWithMedia, Put, PutWithMedia, Delete, GetOutside, companyId };
