import React, { useContext, useEffect, useState } from 'react'
import Login from '../../components/login/Login'
import { Authenticated } from '../../context/SessionContext';
import { Navigate } from 'react-router-dom';

export default function LoginPage() {
  const { authenticated } = useContext(Authenticated);

  return (
    <>
      {authenticated ? <Navigate to={'/events/list'} /> : null}
      <Login />
    </>
  )
}