import { Get } from "../api";

async function getCompany () {
    let address = window.location.hostname;
    // let address = 'dev.liveonbeat.com';

    return Get('/companies/getByAddress/' + address).then(res => {
        return res;
    });
}

export default getCompany;