import { React, useContext, useEffect, useState } from "react";
import { Badge } from "../components/badge";
import { Button } from "../components/button";
import { Divider } from "../components/divider";
import { Heading } from "../components/heading";
import { Input, InputGroup } from "../components/input";
import { Link } from "react-router-dom";
import {
  MagnifyingGlassIcon,
} from "@heroicons/react/16/solid";
import { Get } from "../api";
import { CompanyContext } from "../context/Company";
import moment from "moment/moment";
import { Organization } from "../context/SessionContext";

export default function Events() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProperty] = useState("name");
  const {company} = useContext(CompanyContext);
  const {organization} = useContext(Organization);  
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!company?._id) return;
      try {
        const response = await Get(`/partners/upcomingEventList/${company._id}`);
        if (response?.length > 0) {
          setEvents(response);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [company._id]);

  const filteredEvents = events.filter((eventName) =>
    eventName[selectedProperty]
      .toLowerCase()
      .includes(searchQuery.toLowerCase(0))
  );

  return (
    <>
    <Heading>Events</Heading>
      <div className="flex mt-4 flex-wrap items-center justify-between gap-4">
      
        <div className="flex items-center justify-between gap-4 flex-1">
          
          <div className="flex-1 max-w-xl">
            <InputGroup>
              <MagnifyingGlassIcon />
              <Input
                name="search"
                placeholder="Search events&hellip;"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </InputGroup>
          </div>
          <Button as={Link} to={"/events/past-events"}>
            Past events
          </Button>
        </div>
      </div>
      <ul className="mt-10">
        { filteredEvents.length > 0 && filteredEvents.map((event, index) => (
          <li key={event._id || index}>
            <Divider soft={index > 0} />
            <div className="flex items-center justify-between">
              <div className="flex gap-6 py-6">
                <div className="w-32 shrink-0">
                  <Link to={`/events/event/${event._id}`}>
                    <img
                      className="aspect-[3/2] rounded-lg shadow object-cover"
                      src={event?.promo?.post || event?.promo?.story || organization?.banner_image}
                      alt=""
                    />
                  </Link>
                </div>
                <div className="space-y-1.5">
                  <div className="text-base/6 font-semibold">
                    <Link to={`/events/event/${event._id}`}>{event.name}</Link>
                  </div>
                  <div className="text-xs/6 text-zinc-500">
                    {moment(event.date).format('MMM Do, YYYY')} <span>·</span> {moment(event.time).format('h:mm A')}{" "}
                    <span>·</span> {organization?.city} {organization?.state}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <Badge
                  className="max-sm:hidden"
                  color={event.active ? "lime" : "zinc"}
                >
                  {event.active ? "On Sale" : "Paused"}
                </Badge>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
