import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Heading, Subheading } from '../components/heading'
import { Badge } from '../components/badge'
import { Button } from '../components/button'
import { Stat } from '../components/Stat'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table'
import { Get } from '../api';
import { CompanyContext } from '../context/Company';
import { useContext } from 'react';
import mapTransactionsForAffiliates from '../utils/mapTransactionsForAffiliates'
import moment from 'moment'
import { Organization } from '../context/SessionContext'
import usdConverter from '../helper/usdConverter'
import numberFormater from '../helper/numberFormater'

export default function Event() {
  const {id} = useParams();
  const {company} = useContext(CompanyContext);
  const {organization} = useContext(Organization);
  const [pageData, setPageData] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [sales, setSales] = useState(0);
  const [net, setNet] = useState(0);

  const fetchData = async () => {
    if (!company?._id) return;
    try {
      const [response, affiliateResponse, tickets] = await Promise.all([
        Get(`/partners/getEventDash/${id}`),
        Get(`/partners/getAffiliateStats/${id}/${company?._id}`),
        Get(`/partners/getInventoryStats/${id}`)
      ]);
      setPageData(response);
      setTickets(tickets || []);
      setTransactions(response?.transactions || []);
      setAffiliates(mapTransactionsForAffiliates(affiliateResponse));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

// get total net from ticket.price * ticket.quantity
const netTickets = tickets && Array.isArray(tickets) && tickets.length > 0 ? tickets.reduce((acc, ticket) => {
  return acc + (ticket.price * ticket.soldQuantity);
}, 0) : 0;

const calculateEachTotal = (data) => {
  if (data?.refunded) {
    return 0;
  } else {
    return data?.total;
  }
}

const redueTotal = () => {
  if (transactions.length === 0) return setSales(0);
  const grossSales = transactions.reduce((acc, ticket) => {
    return acc + calculateEachTotal(ticket)
  }, 0);
  setSales(grossSales)
}

const getNetTotal = (data) => {
  return data?.breakdown?.net;
}

const redueNetTotal = () => {
  if (transactions.length === 0) return setNet(0);
  const grossSales = transactions?.reduce((acc, ticket) => {
    return acc + getNetTotal(ticket)
  }, 0);
  setNet(grossSales);
}

useEffect(() => {
  redueTotal();
  redueNetTotal();
}, [transactions, setTransactions]);



  return (
    <div className=''>
      <div className="max-lg:hidden">
        <Link to="/events" className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400">
          <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
          Events
        </Link>
      </div>
      <div className="mt-4 flex flex-wrap items-end justify-between gap-4">
        <div className="flex flex-wrap items-center gap-6">
          <div className="w-32 shrink-0">
            <img className="aspect-[3/2] object-cover rounded-lg shadow" src={pageData?.event?.promo.post || pageData?.event?.promo.banner || pageData?.event?.promo.story || organization?.banner_image} alt="" />
          </div>
          <div>
            <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
              <Heading>{pageData?.event?.name}</Heading>
              <Badge color={'lime'}>On Sale</Badge>
            </div>
            <div className="mt-2 text-sm/6 text-zinc-500">
              {moment(pageData?.event?.start_date).format('lll')} <span>·</span> {organization?.city} {organization?.state}
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <Button link to="/events" outline>Back</Button>
        </div>
      </div>
      <div className="mt-8 grid gap-8 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <Stat title="Gross Revenue" value={usdConverter(sales)} change="+5" />
        <Stat title="Net Tickets" value={usdConverter(netTickets)} change="+5" />
        <Stat title="Payout" value={usdConverter(net)} change="+5" />
        <Stat title="Sold Tickets" value={numberFormater(pageData?.ticketSoldQuantity?.sold || 0)} change="+5" />
        <Stat title="Guest Admitted" value={numberFormater(pageData?.admittedTickets || 0)} change="+5" />
      </div>


      <Subheading className="mt-20">Ticket Inventory</Subheading>
      <Table className="mt- [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Price</TableHeader>
            <TableHeader className="text-right">Sold</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket) => (
            <TableRow key={ticket._id} href={1} title={`Order #25`}>
              <TableCell>{ticket.ticketName}</TableCell>
              <TableCell className="text-zinc-500">{usdConverter(ticket.price)}</TableCell>
              <TableCell className="text-right text-zinc-500">{numberFormater(ticket.soldQuantity)}/{numberFormater(ticket.totalQuantity)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Subheading className="mt-20">Affiliates</Subheading>
      <p className="text-sm/6 text-zinc-500">Only affiliates who has sent over traffic will be shown here.</p>
      <Table className="mt- [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            <TableHeader>Views</TableHeader>
            <TableHeader>Trans</TableHeader>
            <TableHeader className="text-right">Revenue</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {affiliates.map((affiliate, index) => (
            <TableRow 
              key={affiliate._id || `affiliate-${index}`} 
              href={1} 
              title={`Order #25`}
            >
              <TableCell>{affiliate?.affiliateDetails?.name?.charAt(0).toUpperCase() + affiliate?.affiliateDetails?.name?.slice(1)}</TableCell>
              <TableCell className="text-zinc-500">{affiliate.views}</TableCell>
              <TableCell className="text-zinc-500">{affiliate.transactions.length}</TableCell>
              <TableCell className="text-right text-zinc-500">{usdConverter(affiliate.totalAmount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
